$(document).ready(() => {
  setTimeout(() => {
    var mqDesktop = matchMedia('(min-width: 1200px)');

    // // Course detail scrollspy
    var $nav = $('.course-detail-nav');
    var $navInner = $('.course-detail-nav-inner');
    var $lis = $nav.find('li');
    var activeTarget = null;
    var $targets = $();
    $lis.find('a').each(function () {
      var $link = $(this);
      var href = $link.attr('id');
      var $target;
      try {
        $target = $(href);
      } catch (e) {
      }
      if ($target && $target.length) {
        $targets = $targets.add($target);
      }
    });
    var updateActive = function () {
      $lis.removeClass('course-detail-nav-active');
      $lis.find('a').removeAttr('aria-current')
      if (activeTarget) {
        var index = $targets.index(activeTarget);
        if (index >= 0) {
          var $activeLi = $lis.eq(index);
          $activeLi.addClass('course-detail-nav-active');
          $activeLi.find('a').attr('aria-current', 'true');

          if ($navInner.css('overflow-x') !== 'hidden') {   //   [THIBAU]
            $navInner.scrollLeft($activeLi.offset().left + $navInner.scrollLeft() - $navInner.outerWidth() / 2 + $activeLi.outerWidth() / 2);
          }
        }
      }
    };
    var intersectionObserver = new IntersectionObserver(function (entries) {
      activeTarget = null;
      for (var i = 0, l = entries.length; i < l; i++) {
        var entry = entries[i];
        if (entry.boundingClientRect.top < entry.rootBounds.top) {
          activeTarget = entry.target;
        }
      }
      updateActive();
    }, {
      rootMargin: '-50% 0px'
    });
    $targets.each(function () {
      intersectionObserver.observe(this);
    });

    // Course detail expand
    (function () {
      var $main = $('.course-detail-top-main');
      if (!$main.hasClass('course-detail-top-main-expandable')) {
        return;
      }
      var $mainInner = $('.course-detail-top-main-inner');
      var $sideInner = $('.course-detail-top-side-inner');
      var $expandButton = $('.course-detail-top-main-expand-button');
      var expanded = false;
      var expandable = false;
      var resizeObserver = new ResizeObserver(function (entries) {
        var sideInnerH = $sideInner.outerHeight();
        var mainInnerH = $mainInner.outerHeight();
        expandable = sideInnerH < mainInnerH;
        $main[0].style.setProperty('--side-height', sideInnerH + 'px');
        $main.toggleClass('course-detail-top-main-expandable', expandable);
        if (!expandable && expanded) {
          $main.removeClass('course-detail-top-main-expanded');
        }
      });

      var setup = function () {
        resizeObserver.observe($mainInner[0]);
        resizeObserver.observe($sideInner[0]);

        $expandButton.on('click.course-detail', function () {
          if ($main.hasClass('course-detail-top-main-expanded')) {
            $main.removeClass('course-detail-top-main-expanded');
          } else {
            $main.addClass('course-detail-top-main-expanded');
          }
        });
      };
      var teardown = function () {
        resizeObserver.disconnect();
        $expandButton.off('click.course-detail');
      };
      var watcher = function () {
        if (mqDesktop.matches) {
          setup();
        } else {
          teardown();
        }
      };
      if (mqDesktop.addEventListener) {
        mqDesktop.addEventListener('change', watcher);
      } else {
        mqDesktop.addListener(watcher);
      }
      watcher();
    }());
  }, 200);
});
