$(document).ready(() => {
  setTimeout(() => {
    $.initAccordions = function () {
        $('.accordion-panel').off('show.bs.collapse hide.bs.collapse').on('show.bs.collapse', function (e) {
            e.stopPropagation();
            $(this).addClass('accordion-panel-open');
        }).on('hide.bs.collapse', function (e) {
            e.stopPropagation();
            $(this).removeClass('accordion-panel-open');
        });
    };

    $(function () {
        $.initAccordions();
    });
}, 200);
});
