$(document).ready(() => {
  setTimeout(() => {
    var resizeObserver = new ResizeObserver(function (entries) {
        for (var i = 0, l = entries.length; i < l; i++) {
            var entry = entries[i];
            var $caption = $(entry.target);
            var $table = $caption.closest('table');
            var h = $caption.outerHeight();
            $table[0].style.setProperty('--caption-height', h + 'px');
        }
    });
    $.initScrollableTables = function () {
        resizeObserver.disconnect();
        $('.table-scrollable').each(function () {
            var $table = $(this);
            var $tableInner = $table.find('.table-scrollable-inner');
            var $caption = $table.find('caption');
            var updateScrollStatus = function () {
                var sl = $tableInner.scrollLeft();

                $table.toggleClass('table-scrollable-can-scroll-left', sl > 0);
                $table.toggleClass('table-scrollable-can-scroll-right', sl < $tableInner[0].scrollWidth - $tableInner.outerWidth());
            };

            $tableInner.off('scroll.table-scrollable-can-scroll').on('scroll.table-scrollable-can-scroll', updateScrollStatus);
            $tableInner.scrollLeft(0);
            updateScrollStatus();
            if ($caption && $caption.length) {
                resizeObserver.observe($caption[0]);
            }
        });
    };

    $(function () {
        var $win = $(window);
        setTimeout($.initScrollableTables);
        $win.on('resize.table-scrollable-can-scroll', $.initScrollableTables);
    });
  }, 1000);
});
